<div *ngIf="isLoading" class="loading-overlay">
    <app-loading-modal/>
  </div>

<div class="modal fade" id="papelIndividual" tabindex="-1" role="dialog" aria-labelledby="papelIndividualLabel" aria-hidden="true" (click)="preventModalClick($event)" (shown.bs.modal)="clearForm()">

    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header border-0" data-bs-theme="dark">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                            <img class="img-fluid" [src]="imagePath" alt="Imagem de Papel de Parede">
                        </div>
                        <section class="col-md-6 d-grid">
                            <article class="mb-auto">
                                <h2 class="nomeProduto">{{ nomeProduto }}</h2>
                                <p class="descProduto">{{ descProduto }}</p>
                            </article>
                            <article class="mt-3 mb-3">
                                <h2>Calcular rolos de papéis</h2>
                                <form class="row row-cols-lg-3 g-3 align-items-end" (ngSubmit)="submitForm()" [formGroup]="formulario">
                                    <div class="col-12">
                                        <label for="largura" class="form-label">Largura:</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control" id="largura" name="largura" placeholder="000" formControlName="largura"
                                                aria-describedby="larguraCentimetros" required>
                                            <span class="input-group-text" id="larguraCentimetros">cm</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <label for="altura" class="form-label text">Altura:</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control" id="altura" name="altura" placeholder="000" formControlName="altura"
                                                aria-describedby="alturaCentimetros" required>
                                            <span class="input-group-text" id="alturaCentimetros">cm</span>
                                        </div>
                                    </div>
                                    <div class="col-12 align-content-end">
                                        <button type="submit" class="btn btn-primary">Calcular</button>
                                    </div>
                                </form>

                                <p class="mt-3 text-center" *ngIf="numeroDeRolos">{{ numeroDeRolos }}</p>
                            </article>

                            <article class="d-grid mt-auto">
                                <a href="https://api.whatsapp.com/send?phone=5561986792057&text=Quero%20fazer%20um%20pedido"
                                    target="_blank" class="btn btn-success d-flex align-items-center">
                                    <span class="ms-auto">Faça seu pedido</span> 
                                    <i class="fa-brands fa-whatsapp fa-lg ms-auto"></i>
                                </a>
                            </article>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>