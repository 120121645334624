<app-titulo-pagina [titulo]="'Personalizados'"></app-titulo-pagina>
<app-carrossel></app-carrossel>
<app-banner></app-banner>
<div class="container">
    <h2 class="text-center">Dê vida à sua parede com adesivos personalizados!</h2>
    <p class="text-center">Fotos, frases, paisagens... personalize sua parede do seu jeito!</p>
    <br>
    <h4>E não para por aí! A Art House também oferece:</h4>
    <ul>
        <li>
            <i class="fa-regular fa-badge-check"></i><span class="highlight"> Desenvolvimento de artes personalizadas:</span> Nossa equipe de designers
            experientes cria artes exclusivas para você, de acordo com o seu estilo e necessidades.
        </li>
        <li>
            <i class="fa-regular fa-badge-check"></i><span class="highlight"> Acesso ao banco de imagens <a href="https://www.shutterstock.com/">shutterstock.com</a>:</span> Milhares de imagens em alta
            resolução para você escolher e personalizar do seu jeito.
        </li>
        <li>
            <i class="fa-regular fa-badge-check"></i><span class="highlight"> Acesse nosso acervo de painéis já feitos: <a href="https://drive.google.com/drive/folders/1z69Yh4nKs47kKPNuNQjDGM-HLLy7W7_7?usp=sharing">Clique aqui para acessar</a></span>
        </li>
    </ul>
    <br>
    <h4>É fácil e rápido:</h4>
    <ol>
        <li><span class="highlight">Tenha em mente sua ideia:</span> Envie a sua foto, referência ou link do shutterstock.</li>
        <li><span class="highlight">Defina o tamanho:</span> Informe as medidas da sua parede.</li>
        <li><span class="highlight">Solicite orçamento:</span> Clique no botão do WhatsApp e converse com nossa equipe, assim nossos profissionais
            farão uma avaliação da imagem e tamanho da parede para confirmar um bom resultado com alta resolução.
        </li>
        <li><span class="highlight">Receba seu adesivo:</span> No prazo máximo de 7 dias receba seu adesivo.</li>
    </ol>
    <br>
    <h4>Adesivos personalizados da Art House:</h4>
    <ul>
        <li><i class="fa-regular fa-badge-check"></i><span class="highlight"> Alta qualidade de impressão:</span> Cores vibrantes e imagens nítidas que garantem
            um visual impecável.</li>
        <li><i class="fa-regular fa-badge-check"></i><span class="highlight"> Material resistente e durável:</span> Vinil Adesivado de alta qualidade, Ideal para
            ambientes internos.</li>
        <li><i class="fa-regular fa-badge-check"></i><span class="highlight"> Indicamos Instaladores profissionais:</span> Experientes que oferecem toda garantia
            do serviço.</li>
    </ul>
    <br>
    <p>Clique no botão abaixo e solicite já seu orçamento!</p>
    <div class="d-flex justify-content-center mt-auto pt-5 pb-3">
        <article class="d-grid botao-container">
            <a href="https://api.whatsapp.com/send?phone=5561986792057&text=Eu%20gostaria%20de%20solicitar%20um%20orçamento"
                target="_blank" class="btn btn-whatsapp d-flex align-items-center">
                <span class="ms-auto px-3">Solicite seu orçamento</span>
                <i class="fa-brands fa-whatsapp fa-lg ms-auto"></i>
            </a>
        </article>
    </div>
</div>