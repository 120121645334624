<div class="modal fade" id="modalPromocao" tabindex="-1" role="dialog" aria-labelledby="modalPromocaoLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content container">
            <div class="modal-header" data-bs-theme="dark">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-center">
                    <h1 class="text-center text-wrap" id="modalPromocaoLabel">GOSTARIA DE GANHAR 5% DE DESCONTO EM SUA COMPRA</h1>
                </div>
                <p class="text-center mt-2">PREENCHA COM SEU EMAIL E WHATSAPP QUE ENTRAREMOS EM CONTATO</p>
                <app-lead-desconto-form></app-lead-desconto-form>
            </div>
        </div>
    </div>
</div>