import { Component } from "@angular/core";


@Component({
  selector: 'app-personalizados',
  templateUrl: './personalizados.component.html',
  styleUrls: ['./personalizados.component.css']
})
export class PersonalizadosComponent {

}
