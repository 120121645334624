<app-titulo-pagina [titulo]="'Contato'"></app-titulo-pagina>

<div class="container contact-section">
    <div class="row mb-0">
        <div class="col-12 col-md-6 col-lg-8 contact-info">
            <p><a href="https://api.whatsapp.com/send?phone=5561986792057" target=”_blank”><i class="fa-brands fa-whatsapp"></i> (61) 9
                    8679 - 2057</a></p>
            <p><i class="fa-solid fa-phone"></i> (61) 3345 - 4363</p>
            <p><i class="fa-solid fa-envelope"></i> arthouse751<span>&#64;</span>hotmail.com</p>
            <p><a href="https://www.instagram.com/arthousepapel/" target=”_blank”><i class="fa-brands fa-instagram"></i> <span>
                        &#64;</span>arthousepapel</a></p>
            <p><a href="https://www.facebook.com/art.house.papel.de.parede" target=”_blank”><i class="fa-brands fa-facebook"></i> Art
                    House Papel de Parede</a></p>
            <p><a
                    href="https://www.google.com.br/maps/place/Art+House+Papel+de+Parede/@-15.8194305,-47.9171404,17z/data=!3m1!4b1!4m6!3m5!1s0x935a3b0003e14373:0x96184e9b98401abb!8m2!3d-15.8194357!4d-47.9122641!16s%2Fg%2F11vlx9szx7?entry=ttu" target=”_blank”><i
                        class="fa-solid fa-location-dot"></i> CLS
                    311, Bloco C, 29 - Asa Sul, Brasília - DF</a></p>
        </div>
        <div class="d-none d-md-block col-md-6 col-lg-4 contact-image">
            <img src="assets/contato/contato.png" alt="" class="w-100">
        </div>
    </div>
</div>
<div class="divider"></div>
<div class="container container-mensagem">
    <h3 class="text-center">Envie uma mensagem para nós</h3>
    <app-contato-form></app-contato-form>
</div>