<footer class="text-white py-5">
    <div class="container">
        <div class="row gx-4 mb-2">
            <div class="col-lg-2 col-md-3 col-sm-12 mb-3">
                <h5>Atendimento</h5>
                <ul class="list-unstyled">
                    <li class="py-2">
                        <a>
                            <i class="fas fa-phone"></i> (61) 3345-4363
                        </a>
                    </li>
                    <li class="py-2">
                        <a href="https://api.whatsapp.com/send?phone=5561986792057">
                            <i class="fab fa-whatsapp"></i> (61) 98679-2057
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2  col-md-3 col-sm-12 mb-3">
                <h5>Redes Sociais</h5>
                <ul class="list-unstyled">
                    <li class="py-2">
                        <a href="https://www.facebook.com/art.house.papel.de.parede" target=”_blank”>
                            <img src="/assets/social/facebook.png" alt="Facebook" width="24" height="24"> Facebook
                        </a>
                    </li>
                    <li class="py-2">
                        <a href="https://www.instagram.com/arthousepapel/" target=”_blank”>
                            <img src="/assets/social/instagram.png" alt="Instagram" width="24" height="24"><span
                                class="py-2"> Instagram</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-12 pe-5 order-lg-0 order-first mb-3">
                <h5>Receba Nossas Novidades</h5>
                <app-lead-desconto-form></app-lead-desconto-form>
            </div>
            <div class="col-lg-2  col-md-3 col-sm-12 mb-3">
                <h5>Institucional</h5>
                <ul class="list-unstyled">
                    <li class="py-2"><a href="/sobrenos">Sobre Nós</a></li>
                    <li class="py-2"><a href="/contato">Contato</a></li>
                </ul>
            </div>
            <div class="col-lg-2  col-md-3 col-sm-12 mb-3">
                <h5>Selos de Segurança</h5>
                <ul class="list-unstyled">
                    <li class="py-2">
                        <a href="https://transparencyreport.google.com/safe-browsing/search?url=https://arthousepapeldeparede.com.br/"
                            target="_blank" rel="noopener" aria-describedby="a11y-new-window-message"><img
                                src="/assets/logos/norton.png"
                                width="160px"></a>
                                
                    </li>
                    <li class="py-2">
                        <a href="https://transparencyreport.google.com/safe-browsing/search?url=https://arthousepapeldeparede.com.br/"
                            target="_blank" rel="noopener" aria-describedby="a11y-new-window-message"><img
                                src="/assets/logos/google-safe-browsing.png"
                                width="160px"></a>
                    </li>
                </ul>
            </div>
        </div>
        <hr class="my-4">
        <div class="text-center">
            <p>&copy; {{anoAtual}} Art House. Todos os direitos reservados.</p>
        </div>
    </div>
</footer>