<div class="app-container flex-column min-vh-100">
    <app-header></app-header>
    <app-notificacao></app-notificacao>
    <app-modal-promocao></app-modal-promocao>
    <app-whatsapp></app-whatsapp>
    
    <div class="flex-grow-1">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>