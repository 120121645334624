<article class="col h-100">
    <div class="card border-0 h-100">
        <img [src]="imageSrc"
        class="card-img"
        [alt]="nomeProduto"
        loading="lazy"
        [ngClass]="{ 'aspect-ratio-square': aspectRatio === 'square', 'aspect-ratio-rectangular': aspectRatio ===
        'rectangular' }">
        <div class="card-img-overlay">
            <div *ngIf="grupo !== 'Normal' && grupo !== null"
                class="custom-badge d-flex mt-auto position-absolute bottom-0 end-0">
                <div class="badge-content">
                    {{ grupo }}
                </div>
            </div>
        </div>
    </div>
</article>

<app-papel-individual [imagePath]="imagePath" [idProduto]="idProduto" [descProduto]="descProduto"
    [nomeProduto]="nomeProduto"></app-papel-individual>