<section id="carouselExampleIndicators" class="carousel slide mt-5 pt-4 d-none d-md-block" data-bs-ride="carousel">
    <ul class="carousel-indicators">
        <li type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></li>
        <li type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></li>
    </ul>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="/assets/carousel/casa com papel de parede.jpg" class="d-block w-100" height="640" alt="...">
      </div>
      <div class="carousel-item">
        <img src="/assets/carousel/entrada2sobrenos.webp" class="d-block w-100" height="640" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
</section>

<div class="card mt-5 pt-4 d-md-none border-0">
  <div id="carouselMobile" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
          <div class="carousel-item active">
              <img src="/assets/carousel/casa com papel de parede.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
              <img src="/assets/carousel/entrada2sobrenos.webp" class="d-block w-100" alt="...">
          </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselMobile" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>
  </div>
</div>
