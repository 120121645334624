<nav class="navbar navbar-expand navbar-dark">
    <div class="container-fluid">
        <ul class="navbar-nav me-auto w-100">
            <li class="nav-item">
                <button class="btn btn-dark px-3" (click)="toggleFilter()" data-bs-toggle="collapse"
                    data-bs-target="#filtro" aria-expanded="true" aria-controls="filtro">
                    <i [ngClass]="{'fa-solid': filterOpened, 'fa-regular': !filterOpened}" class="fa-filter"></i>
                    Filtros
                </button>
                <span class="d-none d-sm-inline-block align-middle">
                    |
                    <span class="ps-3">
                        {{itemCount }} resultado(s)
                    </span>
                </span>
            </li>
            <li class="nav-item dropdown ms-auto">
                <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ selectedItem === 0 ? 'Lançamentos' : 'Mais Vendidos' }}
                </button>
                <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end mt-2">
                    <li>
                        <a class="dropdown-item d-flex" (click)="changeSorting(0)">
                            Lançamentos
                            <i *ngIf="selectedItem === 0" class="fa-solid fa-check ms-auto align-self-center"></i>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item d-flex" (click)="changeSorting(1)">
                            Mais Vendidos
                            <i *ngIf="selectedItem === 1" class="fa-solid fa-check ms-auto align-self-center"></i>
                        </a>
                    </li>
                </ul>
            </li>
            
            
        </ul>
    </div>
</nav>