<app-titulo-pagina [titulo]="'Sobre nós'"></app-titulo-pagina>
<div class="container container-sobre">
    <div class="row mb-4">
        <div class="col-6 d-none d-lg-block">
            <img class="img-fluid sobre " src="assets/sobreNos/papel de parede geometrico.jpg" alt="papel de parede 3d geometrico quarto">
        </div>
        <div class="sobre col-12 col-lg-6">
            <div class="container-titulo">
                <h2 class="titulo">Art House</h2>
                <h3 class="titulo">Transformando Lares Há 24 Anos!</h3>
            </div>
            <p>Fundada em maio de 2000 em Brasília, nos dedicamos em transformar lares com papel de parede. Somos mais
                do que uma
                simples loja, somos apaixonados por criar ambientes únicos e expressivos, trazendo alegria para cada
                cliente.</p>

            <p class="fw-bold">Nossa história é construída com base em três pilares fundamentais:</p>

            <p><i class="fa-regular fa-badge-check"></i> Paixão pela arte: Acreditamos que o papel de parede vai além do
                revestimento, sendo uma expressão da sua individualidade e estilo.</p>
            <p><i class="fa-regular fa-badge-check"></i> Atendimento impecável: Nossa equipe experiente e dedicada
                oferece atendimento completo, guiando você desde a escolha até o suporte pós-compra.</p>
            <p><i class="fa-regular fa-badge-check"></i> Qualidade incomparável: Oferecemos uma ampla variedade de
                produtos das melhores marcas do mercado, com
                ótimo preços e garantia.</p>

            <p>A Art House oferece mais que produtos: proporcionamos uma experiência personalizada para criar o ambiente
                dos seus sonhos. Com a confiança de milhares de clientes, nossa maior gratidão são suas indicações.
                Transforme seu lar conosco!</p>

            <p>Art House: 24 anos de paixão pelo papel de parede.</p>
        </div>
    </div>
</div>

<img src="assets/sobreNos/Untitled design.jpg" alt="Banner com imagens da empresa" class="img-fluid w-100">

<div class="separador">Elegancia e qualidade no mesmo lugar.</div>

<article class="container-fluid container-valores">
    <div class="row">
        <img class="col-12 col-md-6 p-0" src="assets/sobreNos/missao sobre nos.webp" alt="imagem missao da empresa">
        <div class="col-12 col-md-6">
            <div class="container container-valor">
                <h3 class="py-3">Missão</h3>
                <p>Transformar lares em espaços únicos e expressivos através da arte em papel de parede, proporcionando
                    aos clientes uma experiência de compra impecável e um atendimento personalizado, com foco na
                    qualidade, variedade e preço justo.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 order-last order-md-first">
            <div class="container container-valor">
                <h3 class="py-3">Visão</h3>
                <p>Ser a referência em Brasília em revestimentos de parede, reconhecida pela excelência em produtos,
                    serviços e atendimento, inspirando e transformando a vida das pessoas através da arte.</p>
            </div>
        </div>
        <img class="col-12 col-md-6 p-0" src="assets/sobreNos/visao sobre nos.webp" alt="imagem visão da empresa">
    </div>
    <div class="row">
        <img class="col-12 col-md-6 p-0" src="assets/sobreNos/valores sobre nós.webp" alt="imagem valores da empresa">
        <div class="col-12 col-md-6">
            <div class="container container-valor">
                <h3 class="py-3">Valor</h3>
                <p>Temos paixão pela arte e qualidade, oferecendo atendimento personalizado,
                    variedade e preços justos. Comprometidos com a comunidade, sustentabilidade,
                    ética e transparência, garantindo um ambiente de trabalho feliz e inspirador.
                </p>
            </div>
        </div>
    </div>
</article>