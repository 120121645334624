<nav aria-label="Page navigation">
    <div class="pagination justify-content-center gap-1">
        <li class="page-item">
            <a class="page-link" (click)="prevPage()" aria-label="Previous" [class.disabled]="currentPage === 1">
                <i class="fa-solid fa-angle-left" aria-hidden="true"></i>
            </a>
        </li>
        <span class="page-info">
            Página: {{ currentPage }} de {{ totalPages }}
        </span>
        <li class="page-item">
            <a class="page-link" (click)="nextPage()" aria-label="Next" [class.disabled]="currentPage === totalPages">
                <i class="fa-solid fa-angle-right" aria-hidden="true"></i>
            </a>
        </li>
    </div>
</nav>
