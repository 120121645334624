
<div *ngIf="isLoading" class="loading-overlay">
    <app-loading-modal/>
  </div>
<app-titulo-pagina [titulo]="'Cadastrar papeis de parede'"></app-titulo-pagina>
<div class="container my-4">
    <form [formGroup]="produtoForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
            <label for="tipoProduto" class="form-label">Tipo do Produto:</label>
            <select class="form-select" id="tipoProduto" aria-label="Selecione o tipo do produto" formControlName="tipoProduto">
                <option value="" selected disabled>Selecione o tipo do produto</option>
                <option *ngFor="let tipoProduto of tipos" [value]="tipoProduto.nomeTipoProduto">{{ tipoProduto.nomeTipoProduto }}</option>
            </select>
        </div>
        

        <div class="mb-3" formArrayName="caracteristicasProduto">
            <label class="form-label">Caracteristicas do Produto:</label>
            <div class="form-check" *ngFor="let caracteristica of caracteristicas;let i = index">
                <input class="form-check-input" type="checkbox" [id]="caracteristica.nomeCaracterisiticas" [formControlName]="i" [value]="caracteristica.nomeCaracterisiticas">
                <label class="form-check-label" [for]="caracteristica.nomeCaracterisiticas">{{ caracteristica.nomeCaracterisiticas }}</label>
            </div>
        </div>

        <div class="mb-3" formArrayName="coresProduto">
            <label class="form-label">Cores do Produto:</label>
            <div class="form-check" *ngFor="let cor of cores; let i = index">
                <input class="form-check-input" type="checkbox" [id]="cor.nomeCor" [formControlName]="i" [value]="cor.nomeCor">
                <label class="form-check-label" [for]="cor.nomeCor">{{ cor.nomeCor }}</label>
            </div>
        </div>
        

        <div class="mb-3">
            <label for="statusProduto" class="form-label">Status do Produto:</label>
            <select class="form-select" aria-label="Selecione o status do produto" formControlName="statusProduto">
                <option  value="" disabled selected>Selecione o status do produto</option>
                <option *ngFor="let statusProduto of status" [value]="statusProduto.nomeStatusProduto">{{ statusProduto.nomeStatusProduto }}</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="descricao" class="form-label">Descrição:</label>
            <textarea class="form-control" id="descricao" name="descricao" rows="3" formControlName="descricao"></textarea>
        </div>

        <div class="mb-4">
            <label class="form-label" for="customFile">Imagem papel de parede</label>
            <input type="file" class="form-control" id="customFile" (change)="onFileSelected($event)" />
        </div>
        

        <div class="mb-3">
            <button type="submit" class="btn btn-primary">Cadastrar Produto</button>
        </div>
    </form>
</div>
